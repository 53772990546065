import { Chat, isChat } from "@zall-bot/types";
import { limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { getDataFromCollectionSnapshot } from "../../Firebase/helpers/getDataFromQuerySnapshot";
import { useMyUser } from "../../User/hooks/useMyUser";
import { createChatCallable } from "../callables/createChatCallable";

export const useChatsByTalentId = (talentId?: string) => {
  const userId = useMyUser((state) => state.user?.id);
  const [chats, setChats] = useState<Chat[]>([]);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!talentId) return;
    if (!userId) return;

    const unsubscribe = onSnapshot(
      query(
        getCollectionRef("chats"),
        where("userId", "==", userId),
        where("talentId", "==", talentId),
        orderBy("createdAt", "desc"),
        limit(1)
      ),
      async (snapshot) => {
        const chats = getDataFromCollectionSnapshot(isChat, snapshot);

        if (chats.length) {
          setChats(chats);
          setError(undefined);
          return;
        }

        await createChatCallable({ talentId }).catch(setError);
      },
      setError
    );

    return () => {
      setChats([]);
      unsubscribe();
    };
  }, [talentId, userId]);

  const isLoading = !chats && !error;

  return [chats, isLoading, error] as const;
};
