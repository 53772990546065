import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from "@mui/material";
import { FirebaseError } from "firebase/app";
import {
  AuthErrorCodes,
  OAuthProvider,
  getRedirectResult,
  signInWithCredential,
} from "firebase/auth";
import { FC, useEffect } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { auth } from "../consts/FIREBASE";
import { useFirebaseAuthDialog } from "../hooks/useFirebaseAuthDialog";
import { FirebaseAuthView } from "./FirebaseAuthView";

export const FirebaseAuthDialog: FC = () => {
  const { isOpen, close } = useFirebaseAuthDialog();

  useEffect(() => {
    if (!isOpen) return;
    logEvent("opened_auth_dialog");
  }, [isOpen]);

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (!result) return;
        if (result.operationType === "signIn") {
          logEvent("signed_in");
        }

        if (result.operationType === "link") {
          logEvent("signed_up");
        }
      })
      .catch(async (error) => {
        if (!(error instanceof FirebaseError)) return;
        if (error.code !== AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE) return;

        try {
          const credential = OAuthProvider.credentialFromError(error);
          if (!credential) return;

          await signInWithCredential(auth, credential);
          logEvent("signed_in");
        } catch (error) {
          handleError(error);
        }
      });
  }, []);

  return (
    <DrawerDialog open={isOpen}

      >
      <DialogTitle textAlign="center">Login or Sign up</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FirebaseAuthView />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Go back
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
