import { CollectionName } from "@zall-bot/types";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";

const EMULATOR_URL = "http://localhost:4000/firestore/data";
const CONSOLE_URL = "https://console.firebase.google.com";

type DocumentPath = `${CollectionName}/${string}`;

export function getFirestoreUrl(documentPath: DocumentPath) {
  if (ENVIRONMENT.firebase.isEmulator) {
    return `${EMULATOR_URL}/${documentPath}`;
  }

  const firebaseProjectName = ENVIRONMENT.firebase.projectId;

  if (!firebaseProjectName) return;
  if (typeof window === "undefined") return;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const firebaseUser = window.localStorage.getItem("u");

  return `${CONSOLE_URL}${
    firebaseUser ? `/u/${String(firebaseUser)}` : ""
  }/project/${firebaseProjectName}/firestore/data/~2F${documentPath.replaceAll(
    "/",
    "~2F"
  )}`;
}
