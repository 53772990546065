import { ChatMessage } from "@zall-bot/types";
import { create } from "zustand";

interface State {
  localChatMessages: ChatMessage[];
  add: (localChatMessage: ChatMessage) => void;
  reset: () => void;
}

export const useLocalChatMessages = create<State>((set, get) => ({
  localChatMessages: [],
  add: (localChatMessage) => {
    const { localChatMessages } = get();

    set({
      localChatMessages: [localChatMessage, ...localChatMessages],
    });
  },
  reset: () => {
    set({ localChatMessages: [] });
  },
}));
