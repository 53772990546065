import { Attachment, Talent } from "@zall-bot/types";
import { useCallback, useState } from "react";
import { logEvent } from "../../Common/helpers/logEvent";
import { useIsAnonymous } from "../../Common/hooks/useIsAnonymous";
import { useFirebaseAuthDialog } from "../../Firebase/hooks/useFirebaseAuthDialog";
import { unlockChatMessageAttachmentCallable } from "../callables/unlockChatMessageAttachmentCallable";

interface Params {
  chatId?: string;
  chatMessageId: string;
  talent?: Talent;
  attachment: Attachment;
  attachmentIndex: number;
}

export const useUnlockChatMessageAttachment = (params: Params) => {
  const { chatId, chatMessageId, talent, attachment, attachmentIndex } = params;
  const isAnonymous = useIsAnonymous();
  const [isUnlockingAttachment, setIsUnlockingAttachment] = useState(false);

  const unlockAttachment = useCallback(async () => {
    try {
      if (!chatId) return;
      if (isAnonymous) {
        useFirebaseAuthDialog.getState().open();
        return;
      }

      setIsUnlockingAttachment(true);
      await unlockChatMessageAttachmentCallable({
        chatId,
        chatMessageId,
        attachmentIndex,
      });

      switch (attachment.type) {
        case "IMAGE":
          logEvent("unlocked_image");
          break;
        case "AUDIO":
          logEvent("unlocked_audio");
          break;
      }

      setIsUnlockingAttachment(false);
    } catch (error) {
      setIsUnlockingAttachment(false);
      throw error;
    }
  }, [
    attachment.type,
    attachmentIndex,
    chatId,
    chatMessageId,
    isAnonymous,
  ]);

  return {
    isUnlockingAttachment,
    unlockAttachment,
  };
};
