import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MailIcon from "@mui/icons-material/Mail";
import MessageIcon from "@mui/icons-material/Message";
import {
  Button,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { MainPage } from "../../Main/views/MainPage";

export const HomePage: FC = () => {
  return (
    <MainPage>
      <Stack justifyContent="center" flex={1} spacing={4}>
        
          <Typography variant="h1" textAlign="center">
        <Link to="https://zall.dev" style={{ textDecoration: 'none', color: 'inherit' }}>
            Zall.dev
        </Link>
          </Typography>
        
        <Stack direction="row" spacing={2} justifyContent="center">
          <Tooltip title="Chat" placement="top">
            <IconButton href="/zall">
              <MessageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Email" placement="top">
            <IconButton href="mailto:zallesov@gmail.com">
              <MailIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Calendar" placement="top">
            <IconButton href="https://calendly.com/aleksandr-zalesov">
              <CalendarMonthIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </MainPage>
  );
};

const SButton = styled(Button)(({ theme }) => ({
  minWidth: 220,
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
}));
