import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { storage } from "../../Firebase/consts/FIREBASE";
import { StorageFile } from "../types/StorageFile";

export function useStorageFile(filePath?: string): StorageFile {
  const [url, setUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const startEffect = async () => {
      try {
        if (!filePath) return;
        setIsLoading(true);
        const pathRef = ref(storage, filePath);
        const nextUrl = await getDownloadURL(pathRef);

        setUrl(nextUrl);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error("Unknown error"));
        }
      } finally {
        setIsLoading(false);
      }
    };

    void startEffect();
  }, [filePath]);

  return { url, isLoading, error };
}
