import { Stack } from "@mui/material";
import { Attachment, Talent } from "@zall-bot/types";
import { FC, useCallback, useEffect } from "react";
import { handleError } from "../../../Common/helpers/handleError";
import { useUnlockChatMessageAttachment } from "../../hooks/useUnlockChatMessageAttachment";
import { ChatAttachmentUnlockButton } from "../ChatAttachmentUnlockButton";
import { AudioPlayButton } from "./AudioPlayButton";
import { AudioSkeleton } from "./AudioSkeleton";
import { toAttachmentId } from "./toAttachmentId";
import { useAudioPlayer } from "./useAudioPlayer";

interface Props {
  chatId?: string;
  chatMessageId: string;
  talent?: Talent;
  attachment: Attachment;
  attachmentIndex: number;
}

export const ChatAttachmentAudioView: FC<Props> = (props) => {
  const { attachment, chatMessageId, attachmentIndex, chatId } = props;
  const { filePath, isUnlocked } = attachment;
  const attachmentId = toAttachmentId(chatMessageId, attachmentIndex);
  const isInitialized = useAudioPlayer(
    (state) => state.isInitializedRecord[attachmentId]
  );

  useEffect(() => {
    async function startEffect() {
      if (!filePath) return;
      if (!isUnlocked) return;
      await useAudioPlayer.getState().downloadUrl(attachmentId, filePath);
      await useAudioPlayer.getState().initPlayer(attachmentId);
      if (attachmentId === useAudioPlayer.getState().startedAttachmentId) {
        await useAudioPlayer.getState().play(attachmentId);
      }
    }
    void startEffect();
    return () => {
      useAudioPlayer.getState().destroy(attachmentId);
    };
  }, [attachmentId, filePath, isUnlocked]);

  const { isUnlockingAttachment, unlockAttachment } =
    useUnlockChatMessageAttachment(props);

  // NOTE: It's only used by the unlock button and not by the play button.
  const onClickUnlockAttachment = useCallback(async () => {
    try {
      await unlockAttachment();
    } catch (error) {
      handleError(error);
    }
  }, [unlockAttachment]);

  const isDisabled = isUnlockingAttachment || !chatId;
  const isUnlockButtonVisible =
    !attachment.isUnlocked && !isUnlockingAttachment;

  return (
    <Stack direction="row" spacing={2} flex={1} alignItems="center">
      <AudioPlayButton
        attachmentId={attachmentId}
        filePath={filePath}
        isUnlocked={isUnlocked}
        unlockAttachment={unlockAttachment}
      />
      {isUnlockButtonVisible && (
        <ChatAttachmentUnlockButton
          disabled={isDisabled}
          onClick={onClickUnlockAttachment}
          attachmentType="AUDIO"
        />
      )}
      {!isUnlockButtonVisible && (
        <Stack flex={1} justifyContent="center" position="relative">
          {!isInitialized && (
            <AudioSkeleton sx={{ position: "absolute", left: 0, right: 0 }} />
          )}
          <div
            style={{ visibility: isInitialized ? "visible" : "hidden" }}
            id={attachmentId}
          />
        </Stack>
      )}
    </Stack>
  );
};
