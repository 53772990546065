import { Stack, Typography } from "@mui/material";
import { Talent } from "@zall-bot/types";
import { FC, useEffect } from "react";
import { logEvent } from "../../Common/helpers/logEvent";
import { useIsAnonymous } from "../../Common/hooks/useIsAnonymous";
import { StorageFile } from "../../Common/types/StorageFile";
import { useFirebaseAuthChatMessage } from "../../Firebase/hooks/useFirebaseAuthChatMessage";
import { FirebaseAuthView } from "../../Firebase/views/FirebaseAuthView";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

interface Props {
  talent?: Talent;
  profilePicture?: StorageFile;
}

export const ChatMessageAuthView: FC<Props> = (props) => {
  const { talent, profilePicture } = props;
  const isAnonymous = useIsAnonymous();
  const isVisible = useFirebaseAuthChatMessage((state) => state.isVisible);

  useEffect(() => {
    if (isAnonymous) return;
    useFirebaseAuthChatMessage.getState().hide();
  }, [isAnonymous]);

  useEffect(() => {
    if (!isVisible) return;
    logEvent("opened_auth_dialog");
  }, [isVisible]);

  if (!isVisible) return null;
  if (!talent?.chatMessageForAuth) return null;

  return (
    <ChatMessageLayoutView
      senderType="TALENT"
      profilePicture={profilePicture}
      fullWidth
      actions={
        <Stack borderRadius="18px" mt={1} p={1.5} bgcolor="background.paper">
          <FirebaseAuthView size="small" />
        </Stack>
      }
    >
      <Typography variant="body2">{talent.chatMessageForAuth}</Typography>
    </ChatMessageLayoutView>
  );
};
