import { AttachmentType } from "@zall-bot/types";

interface RecordValue {
  unlockLabel: string;
}

export const ATTACHMET_TYPE_RECORD: Record<AttachmentType, RecordValue> = {
  IMAGE: {
    unlockLabel: "Click to unlock 👀",
  },
  AUDIO: {
    unlockLabel: "Click to unlock 🔊",
  },
};
