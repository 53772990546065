import { FC } from "react";
import { useParams } from "react-router-dom";
import { useStorageFile } from "../../Common/hooks/useStorageFile";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingView } from "../../Common/views/LoadingView";
import { MainPage } from "../../Main/views/MainPage";
import { useTalentsByTalentTag } from "../../Talent/hooks/useTalentsByTalentTag";
import { CreditRewardForSignUpToast } from "../../User/CreditRewardForSignUpToast";
import { useAsyncChat } from "../hooks/useAsyncChat";
import { useChatsByTalentId } from "../hooks/useChatsByTalentId";
import { ChatHeadView } from "./ChatHeadView";
import { ChatInputView } from "./ChatInputView";
import { ChatMessageListView } from "./ChatMessageListView";

export const ChatPage: FC = () => {
  const { talentTag } = useParams<"talentTag">();
  const [[talent], isLoadingTalent, talentError] =
    useTalentsByTalentTag(talentTag);
  const profilePicture = useStorageFile(talent?.profilePictureFilePath);
  const [[chat], , chatError] = useChatsByTalentId(talent?.id);
  useAsyncChat.getState().setChat(chat);

  const talentNotFoundError =
    !isLoadingTalent && !talent ? new Error("Cannot find talent.") : undefined;
  const error = talentError || chatError || talentNotFoundError;

  if (error) return <ErrorPage error={error} />;

  return (
    <MainPage>
      <ChatHeadView
        talent={talent}
        chat={chat}
        profilePicture={profilePicture}
      />
      {isLoadingTalent && <LoadingView />}
      {!isLoadingTalent && (
        <ChatMessageListView
          talent={talent}
          chat={chat}
          profilePicture={profilePicture}
        />
      )}
      <ChatInputView talent={talent} />
      <CreditRewardForSignUpToast talent={talent} />
    </MainPage>
  );
};
