import { Button, Stack } from "@mui/material";
import { Talent } from "@zall-bot/types";
import { Timestamp } from "firebase/firestore";
import { FC, useCallback } from "react";
import { v4 } from "uuid";
import { TIMEZONE } from "../../Common/consts/TIMEZONE";
import { logEvent } from "../../Common/helpers/logEvent";
import { useIsAnonymous } from "../../Common/hooks/useIsAnonymous";
import { useFirebaseAuthChatMessage } from "../../Firebase/hooks/useFirebaseAuthChatMessage";
import { useMyUser } from "../../User/hooks/useMyUser";
import { createChatMessageCallable } from "../callables/createChatMessage";
import { useAsyncChat } from "../hooks/useAsyncChat";
import { useLocalChatMessages } from "../hooks/useLocalChatMessages";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

interface Props {
  talent?: Talent;
}

export const ChatMessageSuggestionsView: FC<Props> = (props) => {
  const { talent } = props;
  const isAnonymous = useIsAnonymous();
  const userId = useMyUser((state) => state.user?.id);
  const isTalentManagedByUser = useMyUser(
    (state) => talent?.id && state.user?.managedTalentIds?.includes(talent.id)
  );
  const isChatMessageAuthVisible = useFirebaseAuthChatMessage(
    (state) => state.isVisible
  );

  const onClickSuggestion = useCallback(
    async (text: string) => {
      if (!talent) return;

      const localId = v4();
      useLocalChatMessages.getState().add({
        id: localId,
        text,
        senderType: "USER",
        senderId: userId || "USER",
        localId,
        createdAt: Timestamp.now(),
      });

      const chat = await useAsyncChat.getState().getChat();

      await createChatMessageCallable({
        chatId: chat.id,
        localId,
        text,
        timezone: TIMEZONE,
      });
      logEvent("sent_chat_message_suggestion", talent.tag);
    },
    [isAnonymous, isTalentManagedByUser, talent, userId]
  );

  if (isChatMessageAuthVisible) return null;
  if (!talent?.chatMessageSuggestions?.length) return null;

  return (
    <ChatMessageLayoutView
      senderType="USER"
      actions={
        <Stack spacing={1}>
          {talent.chatMessageSuggestions.map((suggestion) => (
            <Button
              variant="outlined"
              key={suggestion.id}
              sx={{
                borderRadius: 42,
                whiteSpace: "normal",
                alignSelf: "end",
              }}
              onClick={() => onClickSuggestion(suggestion.text)}
            >
              {suggestion.text}
            </Button>
          ))}
        </Stack>
      }
    />
  );
};
