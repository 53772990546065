import { ChatMessage } from "@zall-bot/types";
import { create } from "zustand";
import { getChatMessageTimeoutInMs } from "../helpers/getChatMessageTimeout";

interface State {
  /**
   * key is `chatMessageId`, value is `isHidden`
   */
  isHiddenRecord: Record<string, boolean>;
  hasHiddenChatMessages: boolean;
  isHidden: (chatMessageId: string) => boolean;
  hide: (chatMessages: ChatMessage[]) => void;
  setIsHiddenRecord: (nextIsHiddenRecord: Record<string, boolean>) => void;
  reset: () => void;
}

export const useChatMessageVisibility = create<State>((set, get) => ({
  hasHiddenChatMessages: false,
  isHiddenRecord: {},
  isHidden: (chatMessageId) => {
    const { isHiddenRecord } = get();

    return Boolean(isHiddenRecord[chatMessageId]);
  },
  hide: (chatMessages: ChatMessage[]) => {
    const nextIsHiddenRecord: Record<string, boolean> = {};

    chatMessages.forEach((chatMessage, index) => {
      nextIsHiddenRecord[chatMessage.id] = true;

      setTimeout(() => {
        get().setIsHiddenRecord({
          ...get().isHiddenRecord,
          [chatMessage.id]: false,
        });
      }, getChatMessageTimeoutInMs(index));
    });

    get().setIsHiddenRecord(nextIsHiddenRecord);
  },
  setIsHiddenRecord: (nextIsHiddenRecord: Record<string, boolean>) => {
    set({
      isHiddenRecord: nextIsHiddenRecord,
      hasHiddenChatMessages: Object.keys(nextIsHiddenRecord).reduce(
        (result, id) => result || nextIsHiddenRecord[id],
        false
      ),
    });
  },
  reset: () => {
    set({ isHiddenRecord: {}, hasHiddenChatMessages: false });
  },
}));
