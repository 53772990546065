import {
  Avatar,
  Skeleton,
  Stack,
  styled,
  Typography
} from "@mui/material";
import { Chat, Talent } from "@zall-bot/types";
import { FC } from "react";
import { StorageFile } from "../../Common/types/StorageFile";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";
import { MainContainer } from "../../Main/views/MainContainer";
import { useMyUser } from "../../User/hooks/useMyUser";
import { ChatHeadMenuButton } from "./ChatHeadMenuButton";

const CHAT_HEADER_HEIGHT = "160px";

interface Props {
  talent?: Talent;
  chat?: Chat;
  profilePicture?: StorageFile;
}

export const ChatHeadView: FC<Props> = (props) => {
  const { talent, chat, profilePicture } = props;
  const isTalentManagedByUser = useMyUser(
    (state) => talent?.id && state.user?.managedTalentIds?.includes(talent.id)
  );
  const isProfilePictureVisible = talent && !profilePicture?.isLoading;

  return (
    <>
      <Stack position="fixed" top={0} left={0} right={0} zIndex={5} >
        <MainContainer>
          <SChatHead direction="row" spacing={2} sx={{
            marginLeft: '1px',
            marginRight: '1px',
            borderTop: `1px solid ${MAIN_PALETTE.primary.main}`,
          }}>
            <Stack>
              {!isProfilePictureVisible && (
                <Skeleton
                  variant="circular"
                  width={AVATAR_SIZE}
                  height={AVATAR_SIZE}
                />
              )}
              {isProfilePictureVisible && (
                <Avatar
                  src={profilePicture?.url}
                  sx={{
                    bgcolor: "primary.main",
                    border: `2px solid ${MAIN_PALETTE.primary.main}`,
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                  }}
                />
              )}
            </Stack>
            <Stack flex={1} justifyContent="center">
              {!talent && (
                <>
                  <Skeleton variant="text" width="50%" />
                  <Skeleton variant="text" width="30%" />
                </>
              )}
              {talent && (
                  <Typography variant="h1">{talent.name}</Typography>
              )}
            </Stack>
            <Stack justifyContent="top">
              <ChatHeadMenuButton talent={talent} chat={chat} />
            </Stack>
          </SChatHead>
        </MainContainer>
      </Stack>
      <Stack height={CHAT_HEADER_HEIGHT} />
    </>
  );
};

const SChatHead = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  backdropFilter: "blur(0.5px)",
  background:
    "linear-gradient(180deg, #202030 0%, #202030 55%, rgba(0,0,0,0) 100%)",
}));

const AVATAR_SIZE = 96;
