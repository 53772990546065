import { Avatar, Stack } from "@mui/material";
import { FC, PropsWithChildren, ReactNode } from "react";
import { StorageFile } from "../../Common/types/StorageFile";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";

const AVATAR_SIZE = 32;

interface Props extends PropsWithChildren {
  senderType: "USER" | "TALENT";
  profilePicture?: StorageFile;
  actions?: ReactNode;
  fullWidth?: boolean;
}

export const ChatMessageLayoutView: FC<Props> = (props) => {
  const { senderType, profilePicture, children, actions, fullWidth } = props;

  return (
    <Stack
      px={1}
      direction={senderType === "TALENT" ? "row" : "row-reverse"}
      justifyContent="flex-start"
      spacing={1}
    >
      {senderType === "TALENT" && (
        <Avatar
          src={profilePicture?.url}
          sx={{
            border: `2px solid ${MAIN_PALETTE.primary.main}`,
            width: AVATAR_SIZE,
            height: AVATAR_SIZE,
          }}
        />
      )}
      <Stack maxWidth="80%" width={fullWidth ? "100%" : undefined}>
        {!!children && (
          <Stack
            p={1.5}
            borderRadius="1px"
            border={`1px solid`}
            borderColor={MAIN_PALETTE.primary.light}
            position="relative"
            spacing={1}
          >
            {children}
          </Stack>
        )}
        {actions}
      </Stack>
    </Stack>
  );
};
