import { Button, Theme, useMediaQuery } from "@mui/material";
import { AttachmentType } from "@zall-bot/types";
import { FC } from "react";
import { ATTACHMET_TYPE_RECORD } from "../consts/ATTACHMET_TYPE_RECORD";

interface Props {
  attachmentType: AttachmentType;
  disabled: boolean;
  onClick: () => void;
}

export const ChatAttachmentUnlockButton: FC<Props> = (props) => {
  const { onClick, disabled, attachmentType } = props;
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Button
      size="large"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        flexDirection: isSmall ? "column" : "row",
        borderRadius: 56 / 2,
      }}
      fullWidth
    >
      {ATTACHMET_TYPE_RECORD[attachmentType].unlockLabel}
    </Button>
  );
};
