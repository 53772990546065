import { Button, darken, lighten, Stack, styled } from "@mui/material";
import { linkWithRedirect } from "firebase/auth";
import { FC, useCallback, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../consts/FIREBASE";
import { getAuthProvider, SignInMethod } from "../helpers/getAuthProvider";
import { useIsWebView } from "../hooks/useIsWebView";
import { FirebaseAuthCopyUrlDialog } from "./FirebaseAuthCopyUrlDialog";
import { FirebaseAuthEmailForm } from "./FirebaseAuthEmailForm";

interface Props {
  size?: "small";
}

export const FirebaseAuthView: FC<Props> = (props) => {
  const { size } = props;
  const isWebView = useIsWebView();
  const [authState] = useAuthState(auth);
  const [isCopyUrlDialogOpen, setIsCopyUrlDialogOpen] = useState(false);

  const submit = useCallback(
    async (signInMethod: SignInMethod) => {
      try {
        if (!authState) throw new Error("No user found.");
        await linkWithRedirect(authState, getAuthProvider(signInMethod));
      } catch (error) {
        handleError(error);
      }
    },
    [authState]
  );

  return (
    <>
      <Stack spacing={size === "small" ? 1 : 2}>
        <FirebaseAuthEmailForm size={size} />
        {size !== "small" && <Stack textAlign="center">OR</Stack>}
        <SGoogleButton
          variant="contained"
          size="large"
          startIcon={
            <img
              src="/assets/auth-google.svg"
              alt="Google"
              width={22}
              height={22}
            />
          }
          onClick={() => {
            if (isWebView) {
              setIsCopyUrlDialogOpen(true);
            } else {
              void submit("google.com");
            }
          }}
        >
          Sign in with Google
        </SGoogleButton>
        {/* <SAppleButton
          variant="contained"
          size="large"
          startIcon={<AppleIcon sx={{ width: 28, height: 28 }} />}
          onClick={() => submit("apple.com")}
        >
          Sign in with Apple
        </SAppleButton> */}
      </Stack>
      <FirebaseAuthCopyUrlDialog
        isOpen={isCopyUrlDialogOpen}
        close={() => setIsCopyUrlDialogOpen(false)}
      />
    </>
  );
};

const SGoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.grey[400]),
  backgroundColor: theme.palette.grey[400],
  "&:hover": {
    backgroundColor: darken(theme.palette.grey[400], 0.2),
  },
  ".MuiButton-startIcon": {
    marginRight: theme.spacing(2),
  },
}));

const SAppleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  "&:hover": {
    backgroundColor: lighten(theme.palette.common.black, 0.07),
  },
  ".MuiButton-startIcon": {
    marginRight: theme.spacing(2),
  },
}));
