import { Box, Skeleton, Stack } from "@mui/material";
import { Attachment, Talent } from "@zall-bot/types";
import { FC, useCallback } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useStorageFile } from "../../Common/hooks/useStorageFile";
import { useUnlockChatMessageAttachment } from "../hooks/useUnlockChatMessageAttachment";
import { ChatAttachmentUnlockButton } from "./ChatAttachmentUnlockButton";

interface Props {
  chatId?: string;
  chatMessageId: string;
  talent?: Talent;
  attachment: Attachment;
  attachmentIndex: number;
}

export const ChatAttachmentImageView: FC<Props> = (props) => {
  const { talent, attachment, chatId } = props;
  const { url } = useStorageFile(attachment.filePath);

  const { isUnlockingAttachment, unlockAttachment } =
    useUnlockChatMessageAttachment(props);

  const onClickUnlockAttachment = useCallback(async () => {
    try {
      await unlockAttachment();
    } catch (error) {
      handleError(error);
    }
  }, [unlockAttachment]);

  if (!talent) return null;
  if (attachment.type !== "IMAGE") return null;

  const aspectRatio = attachment.dimensions
    ? attachment.dimensions.width / attachment.dimensions.height
    : undefined;

  const isDisabled = isUnlockingAttachment || !chatId;

  return (
    <Stack overflow="hidden" borderRadius={2} position="relative">
      {!url && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="auto"
          sx={{ aspectRatio }}
        />
      )}

      {url && (
        <Box
          component="img"
          src={url}
          alt="Unknown"
          width="100%"
          height="auto"
          sx={{
            aspectRatio,
            filter: attachment.isUnlocked ? "none" : "blur(35px)",
          }}
        />
      )}
      {!attachment.isUnlocked && (
        <Stack
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          padding={2}
          alignItems="center"
          justifyContent="center"
        >
          <ChatAttachmentUnlockButton
            onClick={onClickUnlockAttachment}
            disabled={isDisabled}
            attachmentType="IMAGE"
          />
        </Stack>
      )}
    </Stack>
  );
};
