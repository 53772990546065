import { FC } from "react";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useMainInit } from "../hooks/useMainInit";
import { MainRoutes } from "./MainRoutes";

export const MainContentView: FC = () => {
  const { isInitializing, error } = useMainInit();

  if (isInitializing) return <LoadingPage />;
  if (error) return <ErrorPage error={error} />;

  return <MainRoutes />;
};
