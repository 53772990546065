import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { usePathname } from "next/navigation";
import { FC, useCallback, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { DrawerDialog } from "../../Common/views/DrawerDialog";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const FirebaseAuthCopyUrlDialog: FC<Props> = (props) => {
  const { isOpen, close } = props;
  const pathname = usePathname();
  const url = `${ENVIRONMENT.domain}${pathname}`;
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isOpen) return;
    logEvent("opened_copy_url_dialog");
  }, [isOpen]);

  const onClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(url);
      toast.success("Copied URL to clipboard.");
    } catch (error) {
      handleError(error);
    }
  }, [url]);

  return (
    <DrawerDialog open={isOpen}>
      <DialogTitle textAlign="center">Open in a browser</DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Typography textAlign="center">
            Logging in with Google is not supported inside another app.
            <br />
            Copy the URL below and open it in a browser!
          </Typography>
          <Stack spacing={2}>
            <TextField
              inputRef={textFieldRef}
              value={url}
              fullWidth
              onClick={() => textFieldRef.current?.select()}
            />
            <Button
              size="large"
              variant="contained"
              onClick={onClick}
              startIcon={<ContentCopyIcon />}
            >
              Copy URL
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          Go back
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
