import { PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypeBackground {
    gradient: string;
  }
}

export const MAIN_PALETTE = {
  mode: "dark",
  background: {
    default: "#202020",
    paper: "#1F1F1F",
    gradient: "linear-gradient(180deg, #202030 0%, #1C0E21 100%)",
  },
  common: {
    black: "#000000",
    white: "#FFDDFF",
  },
  divider: "rgba(255, 255, 255, 0.1)",
  
  text: {
    primary: "#FFDDFF",
    secondary: "#8C8C8C",
  },
  primary: {
    main: "#FFDDFF",
    light: "rgba(255, 221, 255, 0.3)",
  },
  secondary: {
    main: "#2f273b",
  },
  info: {
    main: "#4999E2",
  },
  warning: {
    main: "#FF5C00",
  },
  error: {
    main: "#FF5252",
  },
  success: {
    main: "#38D075",
  },
  grey: {
    "100": "#493d5c",
    "400": "#3b314a",
    "500": "#2f273b",
    "700": "#201b29",
  },
} satisfies PaletteOptions;
