import { ChatMessage, Talent } from "@zall-bot/types";
import { Timestamp } from "firebase/firestore";

/**
 * This messages are shown while creating the user and chat.
 */
export function generateInitialChatMessages(talent: Talent): ChatMessage[] {
  return talent.initialChatMessages.map<ChatMessage>((message, index) => ({
    ...message,
    senderType: "TALENT",
    senderId: talent.id,
    isInitial: true,
    createdAt: Timestamp.fromMillis(Date.now() + index),
  }));
}
