import { create } from "zustand";

interface State {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
}

export const useFirebaseAuthChatMessage = create<State>((set) => ({
  isVisible: false,
  show: () => set({ isVisible: true }),
  hide: () => set({ isVisible: false }),
}));
