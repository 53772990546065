import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { processEmailLink } from "../helpers/processEmailLink";

export const FirebaseAuthEmailLinkDialog: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const startEffect = async () => {
      try {
        console.log("Processing email link...", pathname);
        await processEmailLink({
          pathname,
          search,
          onStart: () => setIsOpen(true),
          onCompletion: () => {
            setIsOpen(false);
            console.error("Success processing email link");
            navigate(pathname, { replace: true });
          },
        });
      } catch (error) {
        console.error("Error processing email link", error);
        handleError(error);
      }
    };

    void startEffect();
  }, [pathname, search, navigate]);

  if (!isOpen) return null;

  return (
    <DrawerDialog open>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <Stack alignItems="center" p={4}>
          <CircularProgress sx={{ color: "common.white" }} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          startIcon={<ArrowBackIcon />}
          onClick={() => setIsOpen(false)}
        >
          Go back
        </Button>
      </DialogActions>
    </DrawerDialog>
  );
};
