import { Reaction } from "@zall-bot/types";
import { logEvent as logFirebaseAnalyticsEvent } from "firebase/analytics";
import { analytics } from "../../Firebase/consts/FIREBASE";

type EventName =
  | "sent_chat_message"
  | "sent_chat_message_suggestion"
  | "signed_up"
  | "signed_in"
  | "opened_checkout_dialog"
  | "opened_auth_dialog"
  | "opened_copy_url_dialog"
  | `reacted_with_${Lowercase<Reaction>}`
  | "unlocked_image"
  | "unlocked_audio";

export function logEvent(eventName: EventName, itemId?: string) {
  if (!analytics) return;

  try {
    logFirebaseAnalyticsEvent(analytics, eventName, {
      ...(itemId && { item_id: itemId }),
    });
  } catch {
    // Do not throw an error!
  }
}
