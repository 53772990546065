import { Talent } from "@zall-bot/types";
import confetti from "canvas-confetti";
import { FC, useEffect } from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import toast from "react-hot-toast";
import { handleError } from "../Common/helpers/handleError";
import { useIsAnonymous } from "../Common/hooks/useIsAnonymous";
import { auth } from "../Firebase/consts/FIREBASE";
import { requestCreditRewardForSignUpCallable } from "./callables/requestCreditRewardForSignUpCallable";
import { useMyUser } from "./hooks/useMyUser";

interface Props {
  talent?: Talent;
}

export const CreditRewardForSignUpToast: FC<Props> = (props) => {
  const talentId = props.talent?.id;
  const isAnonymous = useIsAnonymous();
  const [authUser] = useIdToken(auth);
  const shouldReceiveCreditRewardForSignUp = useMyUser((state) => {
    const { user } = state;

    if (!user) return false;
    if (user.id !== authUser?.uid) return false; // Needed as there is a brief moment, were the user is not overwritten after a login
    if (!talentId) return false;
    if (user.managedTalentIds?.includes(talentId)) return false;

    return !user.hasReceivedCreditRewardForSignUp;
  });

  useEffect(() => {
    if (isAnonymous) return;
    if (!shouldReceiveCreditRewardForSignUp) return;

    void requestCreditRewardForSignUpCallable({})
      .then(({ creditRewardForSignUp }) => {
        void confetti({
          particleCount: 100,
          spread: 100,
          origin: { y: 0.4 },
        });

        toast.success(
          `You received ${creditRewardForSignUp} credits for signing up!`
        );
      })
      .catch(handleError);
  }, [isAnonymous, shouldReceiveCreditRewardForSignUp]);

  return null;
};
