import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Chat, Talent } from "@zall-bot/types";
import { FC, useCallback, useState } from "react";
import { useAuthState, useIdToken } from "react-firebase-hooks/auth";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { useIsAnonymous } from "../../Common/hooks/useIsAnonymous";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useFirebaseAuthDialog } from "../../Firebase/hooks/useFirebaseAuthDialog";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";
import { useMyUser } from "../../User/hooks/useMyUser";
import { ChatHeadAdminDialog } from "./ChatHeadAdminDialog";

interface Props {
  talent?: Talent;
  chat?: Chat;
}

export const ChatHeadMenuButton: FC<Props> = (props) => {
  const { talent, chat } = props;
  const [isFirebaseAdminDialogOpen, setIsFirebaseAdminDialogOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [authState] = useAuthState(auth);
  const isAdmin = useMyUser((state) => state.user?.role === "ADMIN");
  const userId = authState?.uid;
  const isAnonymous = useIsAnonymous();

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      setAnchorEl(null);
      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  }, []);

  const showFirebaseAdminDialog = useCallback(() => {
    setIsFirebaseAdminDialogOpen(true);
    setAnchorEl(null);
  }, []);

  const showFirebaseAuthDialog = useCallback(() => {
    useFirebaseAuthDialog.getState().open();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        size="large"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{
          borderRadius: "0px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {(ENVIRONMENT.nodeEnv === "development" || isAdmin) && userId && (
          <MenuItem onClick={showFirebaseAdminDialog}>
            <ListItemIcon>
              <PersonIcon sx={{ fill: MAIN_PALETTE.primary.main }}/>
            </ListItemIcon>
            <ListItemText sx={{ color: MAIN_PALETTE.primary.main }}>Admin</ListItemText>
          </MenuItem>
        )}
        {isAnonymous && (
          <MenuItem onClick={showFirebaseAuthDialog}>
            <ListItemIcon>
              <LoginIcon sx={{ fill: MAIN_PALETTE.primary.main }} />
            </ListItemIcon>
            <ListItemText sx={{ color: MAIN_PALETTE.primary.main }}>Login</ListItemText>
          </MenuItem>
        )}
        {!isAnonymous && (
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon sx={{ fill: MAIN_PALETTE.primary.main }} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ color: MAIN_PALETTE.primary.main }}>Logout</Typography>
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <ChatHeadAdminDialog
        talent={talent}
        chat={chat}
        isOpen={isFirebaseAdminDialogOpen}
        close={() => setIsFirebaseAdminDialogOpen(false)}
      />
    </>
  );
};
