import { usePathname } from "next/navigation";
import { FC } from "react";
import { MainContentView } from "./MainContentView";
import { MainInitView } from "./MainInitView";
import { MainRoutes } from "./MainRoutes";

export const MainPathView: FC = () => {
  const pathname = usePathname();
  const isHomePage = pathname === "/";

  // We don't create user sessions on the home page
  if (isHomePage) {
    return <MainRoutes />;
  }

  return (
    <>
      <MainInitView />
      <MainContentView />
    </>
  );
};
