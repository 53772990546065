import { zodResolver } from "@hookform/resolvers/zod";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Alert, Button, Stack } from "@mui/material";
import { sendSignInLinkToEmail } from "firebase/auth";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { auth } from "../consts/FIREBASE";

/**
 * Credits: https://github.com/colinhacks/zod/issues/653#issuecomment-930293063
 */
const emailSchema = z.preprocess(
  (u: unknown) => (typeof u === "string" ? u.trim().toLowerCase() : u),
  z.string().email()
);

const FormValues = z.object({
  email: emailSchema,
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  size?: "small";
}

export const FirebaseAuthEmailForm: FC<Props> = (props) => {
  const { size } = props;
  const [hasBeenSent, setHasBeenSent] = useState(false);
  const { pathname } = useLocation();

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { email: "" },
  });

  const submit = useCallback(
    async (formValues: FormValues) => {
      try {
        const { email } = formValues;
        const emailLink = `${ENVIRONMENT.domain}${pathname}?email=${email}`;

        await sendSignInLinkToEmail(auth, email, {
          url: emailLink,
          handleCodeInApp: true,
        });

        setHasBeenSent(true);
      } catch (error) {
        handleError(error);
      }
    },
    [pathname]
  );

  const isLoading = formState.isSubmitting;
  const isDev = ENVIRONMENT.nodeEnv === "development";

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack spacing={size === "small" ? 1 : 2}>
        {hasBeenSent && (
          <Alert severity={isDev ? "warning" : "success"}>
            {isDev
              ? "Find the link in the emulator terminal and open it in the browser!"
              : "Check your email account and click the login link in our email!"}
          </Alert>
        )}
        {!hasBeenSent && (
          <>
            <ControlledTextField
              name="email"
              control={control}
              disabled={isLoading}
              placeholder="Your Email"
              fullWidth
            />
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              startIcon={<AutoAwesomeIcon />}
            >
              Send Magic Link
            </Button>
          </>
        )}
      </Stack>
    </form>
  );
};
