import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { darken, IconButton, styled } from "@mui/material";
import { FC, useCallback } from "react";
import { handleError } from "../../../Common/helpers/handleError";
import { AUDIO_PLAYER_SIZE } from "./AUDIO_PLAYER";
import { useAudioPlayer } from "./useAudioPlayer";

interface Props {
  attachmentId: string;
  filePath?: string;
  isUnlocked?: boolean;
  unlockAttachment: () => Promise<void>;
}

export const AudioPlayButton: FC<Props> = (props) => {
  const { attachmentId, filePath, isUnlocked, unlockAttachment } = props;
  const hasStarted = useAudioPlayer(
    (state) => state.startedAttachmentId === attachmentId
  );

  const onClick = useCallback(async () => {
    try {
      useAudioPlayer.getState().pause();
      if (hasStarted) return;
      useAudioPlayer.setState({ startedAttachmentId: attachmentId });

      if (filePath && isUnlocked) {
        await useAudioPlayer.getState().play(attachmentId);
        return;
      }

      await unlockAttachment();
    } catch (error) {
      handleError(error);
    }
  }, [attachmentId, filePath, hasStarted, isUnlocked, unlockAttachment]);

  return (
    <SIconButton size="large" onClick={onClick}>
      {hasStarted && <PauseIcon fontSize="inherit" />}
      {!hasStarted && <PlayArrowIcon fontSize="inherit" />}
    </SIconButton>
  );
};

const SIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  color: theme.palette.common.white,
  width: AUDIO_PLAYER_SIZE,
  height: AUDIO_PLAYER_SIZE,
  "&:hover": {
    backgroundColor: darken(theme.palette.grey[700], 0.15),
  },
}));
