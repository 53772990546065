import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ChatPage } from "../../Chat/views/ChatPage";
import { FirebaseAuthDialog } from "../../Firebase/views/FirebaseAuthDialog";
import { FirebaseAuthEmailLinkDialog } from "../../Firebase/views/FirebaseAuthEmailLinkDialog";
import { HomePage } from "../../Home/views/HomePage";

export const MainRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path=":talentTag" element={<ChatPage />} />
      </Routes>
      <FirebaseAuthDialog />
      <FirebaseAuthEmailLinkDialog />
    </>
  );
};
