import { isUser, User } from "@zall-bot/types";
import { onSnapshot } from "firebase/firestore";
import { create } from "zustand";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { setupSentryUser } from "../../Setup/setupSentry";

interface State {
  user?: User;
  isInitializing: boolean;
  subscribe: (userId: string) => void;
  unsubscribe?: () => void;
}

export const useMyUser = create<State>((set) => ({
  isInitializing: true,
  subscribe: (userId) => {
    const unsubscribeFirestore = onSnapshot(
      getDocRef("users", userId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const user = getDataFromDocumentSnapshot(isUser, snapshot);

        setupSentryUser(user);

        set({
          user,
          ...(user && { isInitializing: false }),
        });
      },
      () => {
        set({ user: undefined, isInitializing: false });
      }
    );

    set({
      unsubscribe: () => {
        set({ user: undefined, isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
}));
