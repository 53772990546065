import { ChatMessage, Talent } from "@zall-bot/types";
import { useEffect, useState } from "react";
import { usePrevious } from "../../Common/hooks/usePrevious";
import { useMainInit } from "../../Main/hooks/useMainInit";
import { generateInitialChatMessages } from "../helpers/generateInitialChatMessages";
import { useChatMessageVisibility } from "./useChatMessageVisibility";

export function useInitialChatMessages(
  talent?: Talent
): ChatMessage[] | undefined {
  const previousTalent = usePrevious(talent);
  const [initialChatMessages, setInitialChatMessages] =
    useState<ChatMessage[]>();
  const isHiddenRecord = useChatMessageVisibility(
    (state) => state.isHiddenRecord
  );

  useEffect(() => {
    if (!useMainInit.getState().isNewUser) return;
    if (!talent) return;
    if (talent.id === previousTalent?.id) return;

    const nextInitialChatMessages = generateInitialChatMessages(talent);

    useChatMessageVisibility.getState().hide(nextInitialChatMessages);
    setInitialChatMessages([...nextInitialChatMessages].reverse());
  }, [previousTalent, talent]);

  // This effect is needed to trigger a re-render when visibility changes
  useEffect(() => {
    if (!initialChatMessages) return;
    setInitialChatMessages([...initialChatMessages]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHiddenRecord]);

  return initialChatMessages;
}
