import { Stack, styled, Typography } from "@mui/material";
import { ChatMessage, Talent } from "@zall-bot/types";
import { FC, Fragment } from "react";
import { StorageFile } from "../../Common/types/StorageFile";
import { ChatAttachmentAudioView } from "./ChatAttachmentAudioView";
import { ChatAttachmentImageView } from "./ChatAttachmentImageView";
import { ChatMessageLayoutView } from "./ChatMessageLayoutView";

interface Props {
  chatId?: string;
  chatMessage: ChatMessage;
  talent?: Talent;
  talentProfilePicture?: StorageFile;
  isLastMessage?: boolean;
}

export const ChatMessageView: FC<Props> = (props) => {
  const { chatId, chatMessage, talent, talentProfilePicture, isLastMessage } =
    props;

  const isChatMessageTextVisible = !chatMessage.attachments
    ?.map((a) => a.type)
    .includes("AUDIO");

  return (
    <ChatMessageLayoutView
      senderType={chatMessage.senderType}
      profilePicture={talentProfilePicture}
      fullWidth={!!chatMessage.attachments?.length}
    >
      {chatMessage.attachments?.map((attachment, index) => (
        <Fragment key={index}>
          {attachment.type === "IMAGE" && (
            <ChatAttachmentImageView
              chatId={chatId}
              chatMessageId={chatMessage.id}
              talent={talent}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
          {attachment.type === "AUDIO" && (
            <ChatAttachmentAudioView
              chatId={chatId}
              chatMessageId={chatMessage.id}
              talent={talent}
              attachment={attachment}
              attachmentIndex={index}
            />
          )}
        </Fragment>
      ))}
      {isChatMessageTextVisible && (
        <Typography variant="body2">{chatMessage.text}</Typography>
      )}
    </ChatMessageLayoutView>
  );
};

const REACTION_SIZE = 24;

const SReactionView = styled(Stack)(({ theme }) => ({
  position: "absolute",
  bottom: REACTION_SIZE / -3,
  right: theme.spacing(1),
  width: REACTION_SIZE,
  height: REACTION_SIZE,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.grey[500],
  fontSize: 12,
  borderRadius: REACTION_SIZE / 2,
  alignItems: "center",
  justifyContent: "center",
}));
