import { signInWithCustomToken } from "firebase/auth";
import { FC, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/consts/FIREBASE";
import { signInAnonymously } from "../../Firebase/helpers/signInAnonymously";
import { useMyUser } from "../../User/hooks/useMyUser";
import { useCustomTokenFromUrl } from "../hooks/useCustomTokenFromUrl";
import { useMainInit } from "../hooks/useMainInit";

export const MainInitView: FC = () => {
  const navigate = useNavigate();
  const customToken = useCustomTokenFromUrl();
  const [authState, isInitializingAuthState] = useAuthState(auth);
  const userId = authState?.uid;

  useMainInit.getState().onAuthState(authState, isInitializingAuthState);

  // Dependency: userId, isInitializingAuthState, customToken, navigate
  useEffect(() => {
    if (isInitializingAuthState) return;

    console.log("Loggin with custom token: ", customToken);
    if (customToken) {
      signInWithCustomToken(auth, customToken)
        .then(() => navigate(window.location.pathname, { replace: true }))
        .catch((error: Error) => useMainInit.setState({ error }));
      return;
    }

    if (userId) return;

    signInAnonymously().catch((error: Error) => {
      useMainInit.setState({ error });
    });
  }, [userId, isInitializingAuthState, customToken, navigate]);

  // Dependency: userId
  useEffect(() => {
    if (userId) {
      useMyUser.getState().subscribe(userId);
    }

    return () => {
      useMyUser.getState().unsubscribe?.();
    };
  }, [userId]);

  const isInitializing = isInitializingAuthState;

  useEffect(() => {
    useMainInit.setState({ isInitializing });
  }, [isInitializing]);

  return null;
};
