import { zodResolver } from "@hookform/resolvers/zod";
import Play from "@mui/icons-material/PlayArrow";
import { IconButton, Stack, styled } from "@mui/material";
import { Talent } from "@zall-bot/types";
import { Timestamp } from "firebase/firestore";
import { FC, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";
import { TIMEZONE } from "../../Common/consts/TIMEZONE";
import { handleError } from "../../Common/helpers/handleError";
import { logEvent } from "../../Common/helpers/logEvent";
import { useIsAnonymous } from "../../Common/hooks/useIsAnonymous";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";
import { MainContainer } from "../../Main/views/MainContainer";
import { useMyUser } from "../../User/hooks/useMyUser";
import { createChatMessageCallable } from "../callables/createChatMessage";
import { useAsyncChat } from "../hooks/useAsyncChat";
import { useLocalChatMessages } from "../hooks/useLocalChatMessages";

const CHAT_FOOTER_HEIGHT = "72px";

const FormValues = z.object({
  text: z.string(),
});

export type FormValues = z.infer<typeof FormValues>;

interface Props {
  talent?: Talent;
}

export const ChatInputView: FC<Props> = (props) => {
  const { talent } = props;
  const isAnonymous = useIsAnonymous();
  const userId = useMyUser((state) => state.user?.id);
  const isTalentManagedByUser = useMyUser(
    (state) => talent?.id && state.user?.managedTalentIds?.includes(talent.id)
  );
  
  const inputRef = useRef<HTMLInputElement>();
  const { control, handleSubmit, setValue } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { text: "" },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const text = formValues.text.trim();

        if (!text) return;
        if (!talent) return;

        setValue("text", "");
        inputRef.current?.focus();

        const localId = v4();
        useLocalChatMessages.getState().add({
          id: localId,
          text,
          senderType: "USER",
          senderId: userId || "USER",
          localId,
          createdAt: Timestamp.now(),
        });

        const chat = await useAsyncChat.getState().getChat();

        await createChatMessageCallable({
          chatId: chat.id,
          localId,
          text,
          timezone: TIMEZONE,
        });
        logEvent("sent_chat_message", talent.tag);
      } catch (error) {
        handleError(error);
        useLocalChatMessages.getState().reset();
      }
    },
    [setValue, userId, isAnonymous, isTalentManagedByUser, talent]
  );

  return (
    <>
      <Stack position="fixed" bottom={0} left={0} right={0} zIndex={5}>
        <MainContainer>
          <Stack
            position="relative"
            zIndex={1}
            direction="row"
            alignItems="center"
            p={1}
            spacing={1}
          >

            <Stack
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              direction="row"
              sx={{ backgroundColor: MAIN_PALETTE.secondary.main }}
              flex={1}
            >
              <ControlledTextField
                name="text"
                control={control}
                inputRef={inputRef}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                placeholder="Chat"
                autoComplete="off"
                disabled={!talent}
                fullWidth
              />
              <Stack
                justifyContent="center" pr={0.5}>
                <SIconButton type="submit">
                  <Play />
                </SIconButton>
              </Stack>
            </Stack>
          </Stack>
        </MainContainer>
      </Stack>
      <Stack height={CHAT_FOOTER_HEIGHT} />
    </>
  );
};

const SIconButton = styled(IconButton)(({ theme }) => ({
  width: 48,
  height: 48,
  borderColor: theme.palette.grey[100],
  borderStyle: "solid",
  backgroundColor: theme.palette.grey[400],

  "@media(hover: hover)": {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
